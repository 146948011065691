import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";
import PageContent from "../../../components/editor/pageContent";
import RadioTag from "../../../components/form/radioTag";
import { arrayMove } from "react-sortable-hoc";

import {
  showNotification,
  slugify,
  toPromise,
  getParameterByName,
  isFS,
  getEditorType,
  getUniqueId,
  imageUrl,
  checkGMSMessage,
} from "../../../helpers";
import {
  callCreateLandingPage,
  callUpdateLandingPage,
  callGetLandingPageById,
  callGetLandingPagesUsingQuery,
  callDeleteLandingPageById,
  callCreateEditorDraft,
  callCreateLandingPageVisit,
  callGetEmailUsingId,
  callCreateEmail,
  callUpdateEmail,
  callGetEventsUsingQuery,
  callCheckTier,
  callCreateSystemTag,
  callGetSystemTags,
  callGetEmails,
  callGetTextMessagesByQuery,
  callGeneralPost,
} from "../../../services";
import {
  callEmailTemplateById,
  callGetSettings,
} from "../../../services/settingService";
import ScriptPopup from "../../../components/general/scriptPopup";
import { getItem } from "../../../helpers/storage";
import { SortableSubPageList } from "../../../components/form/sortableList";
import { VelocityTransitionGroup } from "velocity-react";
import config from "../../../helpers/config";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";

import $ from "jquery";
import moment from "moment-timezone";
import ViewSubmissions from "../user/viewSubmissions";
import AutoSuggest from "../../../components/form/autoSuggest";
import ChallengeOperations from "../challenge/challengeOpts";
import Checkbox from "../../../components/form/checkBox";

class AddLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let pageId = getParameterByName("id") || this.props.id;

    let selectedTab = "details";
    let tab = getParameterByName("tab") || this.props.tab;
    if (tab) {
      selectedTab = tab;
    }

    let tabOptions = [
      { label: "Details", key: "details", selected: selectedTab === "details" },
      { label: "Content", key: "content", selected: selectedTab === "content" },
    ];

    let tabFunnelOptions = [
      { label: "Facebook", key: "facebook", selected: true },
      { label: "Google Tag Manager", key: "google", selected: false },
    ];

    let page = {
      pageId: pageId ? pageId : 0,
      name: "",
      description: "",
      url: "",
      contentHtml: "",
      contentObject: "",
      contentType: "",
    };

    this.state = {
      selectedTab,
      tabOptions,
      selectedFunnelTab: "facebook",
      tabFunnelOptions,
      pageId: page.pageId,
      name: page.name,
      description: page.description,
      url: page.url,
      landingPageId: "",
      contentHtml: page.contentHtml,
      contentObject: page.contentObject,
      contentType: page.contentType,
      accountKey: "",
      domainList: [],
      pageContent: Math.random(),
      scriptData: {},
      editorType: isFS()
        ? "mp"
        : getItem("de") === "classic"
        ? "unlayer"
        : "mp",
      draftId: "",
      draftImage: "",
      keySelectType: Math.random(),
      keySelectSubType: Math.random(),
      type: "sales",
      subType: "sales",
      subPageList: [],
      subPageInfo: [],
      subPageInfoList: [],
      defaultPageId: pageId ? pageId : 0,
      showSetting: false,
      addNewSubPage: false,
      stats: {
        totalVisits: 0,
        uniqueVisits: 0,
        conversions: 0,
      },
      splitPages: [],
      selectedSplitPage: "original",
      splitPageName: "",
      notiType: "none",
      keyNotiType: Math.random(),
      emailId: "",
      emailSubject: "",
      emailContent: {},
      emailHtml: "",
      keyContent: Math.random(),
      eventId: "",
      selectedTimeType: "",
      selectedHour: "",
      selectedMins: "",
      selectedTimeIndex: -1,
      selectedDay: "",
      selectedMonth: moment().format("MM"),
      selectedYear: moment().format("YYYY"),
      events: [],
      pixelId: "",
      keyRadioSelect: Math.random(),
      fbPixel: "yes",
      leadPixel: "no",
      purchasePixel: "no",
      defaultFbPixel: "yes",
      googleTagId: "",
      googleTag: "yes",
      googleLead: "no",
      googlePurchase: "no",
      loadingPage: true,
      timezone: "America/Denver",
      lastSelectedPageId: "",
      ruleType: "",
      onSpecificDay: "",
      actionType: "",
      rules: [],
      tagType: "existing",
      keyTagType: Math.random(),
      tagValue: "",
      tagSelected: {},
      keyTag: Math.random(),
      tagName: "",
      tags: [],
      emailNotiName: "",
      emailNotiContent: {},
      emailNotiHtml: "",
      keyPageContent: Math.random(),
      sendTimeType: "send",
      smsName: "",
      smsMessage: "",
      isGMSMessage: true,
      noOfMessages: 1,
      isRuleLoading: false,
      keyScriptPopup: Math.random(),
      useTheme: false,
      changeDefaultValues: false,
      keyChangeDefaultValues: Math.random(),
      selectedTheme: "simple",
      keySelectedTheme: Math.random(),
      keyUseTheme: Math.random(),
      showHeader: false,
      showFooter: false,
      keyHeader: Math.random(),
      keyFooter: Math.random(),
    };
  }

  componentDidMount() {
    this.getSystemTags();
    if (this.state.pageId === 0) {
      this.checkTier();
    } else {
      this.setState({ loadingPage: false });
    }
    this.getSettings();
    this.getLandingPageInfo();
    this.getEvents();
  }

  async checkTier() {
    var response = await callCheckTier("num_landing_pages");
    if (!response.status) {
      this.props.history.push("/admin/tier");
    } else {
      this.setState({ loadingPage: false });
    }
  }

  async getSystemTags() {
    let d = await callGetSystemTags();
    this.setState({ tags: d.data, keyAddTag: Math.random() });
  }

  async getSettings() {
    let d = await callGetSettings("busud");
    let data = d.data;
    let accountKey = data["business_details"][0]["unique_id"];
    let domainList = data["business_details"][0]["domain_list"];
    this.setState({ accountKey, domainList });
  }

  async getEvents() {
    var response = await callGetEventsUsingQuery({
      query: { $select: ["_id", "name"] },
    });
    this.setState({ events: response.data });
  }

  async getLandingPageInfo() {
    if (this.state.pageId !== 0) {
      let pData = await callGetLandingPageById(this.state.pageId);
      let subPageInfos = [];
      let subPageList = pData.sub_pages || [];
      let defaultPageInfo = await this.setPageInfo(pData);
      subPageInfos.push(defaultPageInfo);

      let response1 = await callCreateLandingPageVisit(this.state.pageId);

      this.setState({ stats: response1 });

      /*eslint-disable*/
      let query = {
        query: {
          sub_id: pData._id,
        },
      };
      /*eslint-enable*/

      let subPageInfoList = [];

      let response = await callGetLandingPagesUsingQuery(query);
      if (response.data.length > 0) {
        subPageList.forEach((element) => {
          let ds = response.data.filter((d) => d.unique_id === element.id);
          if (ds.length > 0) {
            subPageInfos.push(ds[0]);
            subPageInfoList.push(ds[0]);
          }
        });
        this.setState({
          subPageList: subPageList,
          subPageInfo: subPageInfos,
          subPageInfoList: subPageInfoList,
        });
        if (this.state.lastSelectedPageId) {
          let info = subPageInfos.filter(
            (d) => d._id === this.state.lastSelectedPageId
          );
          if (info.length > 0) {
            await this.setPageInfo(info[0]);
          }
        }
      }
    }
  }

  async setPageInfo(pData) {
    let page = {
      pageId: 0,
      name: "",
      description: "",
      url: "",
      contentHtml: "",
      contentObject: "",
      contentType: "",
      editorType: "unlayer",
      draftId: "",
      draftImage: "",
      type: "sales",
      splitPages: [],
      emailSubject: "",
      emailContent: {},
      emailHtml: "",
      emailId: "",
      eventId: "",
      pixelId: "",
      googleTagId: "",
      landingPageId: "",
      image: "",
    };

    page._id = pData._id;
    page.pageId = pData._id;
    page.landingPageId = pData.page_id || "";
    page.name = pData.name;
    page.description = pData.description;
    page.url = pData.url;
    page.type = pData.type || "sales";
    page.contentHtml = pData.content_html || "";
    page.contentObject = pData.content_object;
    page.contentType = pData.content_type;
    page.draftId = pData.draft_id || "";
    page.draftImage = pData.draft_image || "";
    page.splitPages = pData.split_pages || [];
    page.pixelId = pData.pixel_id || "";
    page.googleTagId = pData.google_tag_id || "";
    page.image = pData.image || "";
    page.imageStatus = pData.image_status || "pending";

    page.editorType = getEditorType(page.contentHtml, page.contentObject);

    let notification = pData.notification || {};

    page.notiType = notification.type || "none";
    page.emailId = notification.email_id || "";
    page.eventId = notification.event_id || "";

    let date = moment();
    let time = moment().minutes(15);
    let timezone = notification.timezone || this.state.timezone;

    if (page.notiType === "email" && page.emailId) {
      var response = await callGetEmailUsingId(page.emailId);
      page.emailSubject = response.title || "";
      page.emailHtml = response.html || "";
      page.emailContent = response.content || {};
    } else if (page.notiType === "event" && page.eventId) {
      time = moment(notification.event_time, "hh:mm A");
      date = moment(notification.event_date, "yyyy-MM-DDThh:mm A");
    }

    let rules = pData.rules || [];
    this.setState({ isRuleLoading: true });

    rules = await this.prepareRules(rules);

    let theme = pData.theme;
    let useTheme = false;
    let showHeader = false;
    let showFooter = false;
    let selectedTheme = "simple";
    let changeDefaultValues = false;

    if (theme) {
      useTheme = theme.enabled;
      showHeader = theme.showHeader;
      showFooter = theme.showFooter;
      selectedTheme = theme.theme;
      changeDefaultValues = theme.changeDefault;
    }

    this.setState({
      changeDefaultValues: changeDefaultValues,
      keyChangeDefaultValues: Math.random(),
      pageId: page.pageId,
      name: page.name,
      description: page.description,
      url: page.url,
      contentHtml: page.contentHtml,
      contentObject: page.contentObject,
      contentType: page.contentType,
      pageContent: Math.random(),
      scriptData: pData.script_data,
      keyScriptPopup: Math.random(),
      editorType: page.editorType,
      draftId: page.draftId,
      draftImage: page.draftImage,
      splitPages: page.splitPages,
      notiType: page.notiType,
      emailId: page.emailId,
      emailSubject: page.emailSubject,
      emailContent: page.emailContent,
      emailHtml: page.emailHtml,
      keyContent: Math.random(),
      keyNotiType: Math.random(),
      pixelId: page.pixelId,
      googleTagId: page.googleTagId,
      landingPageId: page.landingPageId,
      rules: rules,
      isRuleLoading: false,
      tabOptions: [
        {
          label: "Details",
          key: "details",
          selected: this.state.selectedTab === "details",
        },
        {
          label: "Content",
          key: "content",
          selected: this.state.selectedTab === "content",
        },
        {
          label: "After Form Completion",
          key: "rules",
          selected: this.state.selectedTab === "rules",
        },
        {
          label: "Page Settings",
          key: "settings",
          selected: this.state.selectedTab === "settings",
        },
        {
          label: "Pixels",
          key: "funnel-settings",
          selected: this.state.selectedTab === "funnel-settings",
        },
        {
          label: "Submissions",
          key: "registrations",
          selected: this.state.selectedTab === "registrations",
        },
      ],
      eventId: page.eventId,
      selectedHour: time.format("hh"),
      selectedMins: time.format("mm"),
      selectedTimeType: time.format("A"),
      selectedDay: date.format("DD"),
      selectedMonth: date.format("MM"),
      selectedYear: date.format("yyyy"),
      fbPixel: pData.script_data ? pData.script_data.fbPixel || "yes" : "yes",
      leadPixel: pData.script_data ? pData.script_data.leadPixel || "no" : "no",
      purchasePixel: pData.script_data
        ? pData.script_data.purchasePixel || "no"
        : "no",
      defaultFbPixel: pData.script_data
        ? pData.script_data.defaultFbPixel || "yes"
        : "no",
      googleLead: pData.script_data
        ? pData.script_data.googleLead || "yes"
        : "yes",
      googlePurchase: pData.script_data
        ? pData.script_data.googlePurchase || "no"
        : "no",
      googleTag: pData.script_data ? pData.script_data.googleTag || "no" : "no",
      keyRadioSelect: Math.random(),
      timezone: timezone,
      useTheme: useTheme,
      selectedTheme: selectedTheme,
      keyUseTheme: Math.random(),
      showHeader: showHeader,
      showFooter: showFooter,
      keyHeader: Math.random(),
      keyFooter: Math.random(),
      keySelectedTheme: Math.random(),
    });
    return page;
  }

  resetProps() {
    this.setState({
      pageId: 0,
      name: "",
      description: "",
      url: "",
      contentHtml: "",
      contentObject: "",
      contentType: "",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handlePageChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (value === "original") {
          this.getLandingPageInfo();
        } else {
          let f = this.state.splitPages.filter((d) => d.id === value);
          if (f.length > 0) {
            this.setState({
              contentObject: getUpdatedEditorContent(f[0].object, f[0].html),
              contentHtml: f[0].html,
              draftId: f[0].draftId,
              draftImage: f[0].draftImage,
              pageContent: Math.random(),
            });
          }
        }
      }
    );
  }

  handlePageInput(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.checkPageInfo(value);
      }
    );
  }

  async checkPageInfo(value) {
    this.setState({ lastSelectedPageId: value });

    if (value === this.state.defaultPageId) {
      let pData = await callGetLandingPageById(value);
      await this.setPageInfo(pData);
    } else {
      let info = this.state.subPageInfo.filter((d) => d._id === value);
      if (info.length > 0) {
        await this.setPageInfo(info[0]);
      }
    }
  }

  handleOnBlurName(event) {
    const value = event.target.value;
    if (value.toLowerCase() === this.state.url || this.state.url.length === 0) {
      this.setState({ url: slugify(value) + "-" + getUniqueId() });
    }
  }

  onChangeContent(html, content, draftId, draftImage) {
    if (this.state.selectedSplitPage === "original") {
      this.setState(
        {
          contentObject: content,
          contentHtml: html,
          draftId: draftId,
          draftImage: draftImage,
          pageContent: Math.random(),
        },
        () => {
          this.onPressSave();
        }
      );
    } else {
      let pages = this.state.splitPages;
      pages.forEach((element) => {
        if (element.id === this.state.selectedSplitPage) {
          element.html = html;
          element.object = content;
          element.draftId = draftId;
          element.draftImage = draftImage;
        }
      });
      this.setState({ spiltPages: pages }, () => {
        this.onPressSave();
      });
    }
  }

  getPreviewLink() {
    let landingUrl = "";
    if (this.state.url.length > 0) {
      let domainList = this.state.domainList;
      let landingUrls = domainList.filter((d) => {
        return d.type === "landing";
      });
      let url = slugify(this.state.url);

      let defaultDomain = "";
      domainList.forEach((domain) => {
        if (domain.type === "default") {
          defaultDomain = domain.domain;
        }
      });
      let subDomain = defaultDomain.split(".")[0] || "";

      landingUrl = `https://site.memberpages.app/${subDomain}/${url}`;
      if (landingUrls.length > 0) {
        landingUrl = `https://${landingUrls[0].domain}/${url}`;
      }
    }
    return landingUrl;
  }

  onSortEndSubPageList = ({ oldIndex, newIndex }) => {
    let subPageList = this.state.subPageList;
    subPageList = arrayMove(subPageList, oldIndex, newIndex);

    this.setState({
      subPageList: subPageList,
    });
  };

  onPressRemoveSubPage(e) {
    let index = e.target.dataset["value"];
    let items = this.state.subPageList;
    items.splice(index, 1);
    this.setState({
      subPageList: items,
    });
  }

  onSortEndSubPageInfoList = ({ oldIndex, newIndex }) => {
    let subPageInfoList = this.state.subPageInfoList;
    subPageInfoList = arrayMove(subPageInfoList, oldIndex, newIndex);

    this.setState({
      subPageInfoList: subPageInfoList,
    });
  };

  async onPressRemoveSubPageInfo(e) {
    let index = e.target.dataset["value"];
    let items = this.state.subPageInfoList;
    let item = items[index];
    await callDeleteLandingPageById(item._id);
    items.splice(index, 1);
    this.setState({
      subPageInfoList: items,
    });
  }

  onPressAddSubPage(type = "add") {
    let data = {
      type: this.state.subType,
      id: getUniqueId(),
    };
    let subPageList = this.state.subPageList;
    subPageList.push(data);
    this.setState(
      {
        subPageList,
        subType: "sales",
        keySelectSubType: Math.random(),
      },
      () => {
        if (type === "update") {
          this.createNewSubPageFromUpdate(data);
        }
      }
    );
  }

  async createNewSubPageFromUpdate(element) {
    this.setState({ addNewSubPage: false });
    let template = await this.prepareSubPage(element, this.state.pageId);
    await toPromise(callCreateLandingPage(template));
    await this.onPressSave();
    this.getLandingPageInfo();
  }

  onPressPreview() {
    if (this.state.url.length > 0) {
      let landingUrl = this.getPreviewLink();
      window.open(landingUrl, "_blank");
    }
  }

  async saveDraft(html, design) {
    /*eslint-disable*/
    let data = {
      member_id: "",
      title: this.state.name,
      screen: "add-landing-page",
      html: html,
      editor_content: design,
      content_type: this.state.contentType,
    };
    /*eslint-enable*/
    let d = await callCreateEditorDraft(data);
    return d._id;
  }

  async prepareSubPage(element, subId) {
    let id = "";
    let draftId = "";
    let templateType = "";
    if (element.type === "sales") {
      id = config.editorTemplateIds.salesLandingPage;
      templateType = "salesPage";
    }
    if (element.type === "optin") {
      id = config.editorTemplateIds.optinLandingPage;
      templateType = "optinPage";
    }
    if (element.type === "thankyou") {
      id = config.editorTemplateIds.thankYouLandingPage;
      templateType = "thankYouPage";
    }
    let template = {};
    if (id) {
      let apiData = {
        action: "get-template-content",
        data: {
          type: templateType,
        },
      };

      let response = await callGeneralPost(apiData);
      if (response._id) {
        template = response;
        draftId = await this.saveDraft(template.html, template.editor_content);
      } else {
        let editorData = await callEmailTemplateById(id);
        if (editorData.data._id) {
          template = editorData.data;
          draftId = await this.saveDraft(
            template.html,
            template.editor_content
          );
        }
      }
    }

    /*eslint-disable*/
    return {
      name: this.state.name,
      description: this.state.description,
      url: `${slugify(this.state.name)}-${getUniqueId()}`,
      content_html: template.html || "",
      content_object: template.editor_content || {},
      content_type: this.state.contentType,
      draft_id: draftId,
      draft_image: "",
      type: element.type,
      sub_id: subId,
      unique_id: element.id,
    };
    /*eslint-enable*/
  }

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    let url = this.state.url || slugify(this.state.name);

    // if (url.trim().length === 0) {
    //   showNotification("error", "Url is required", 4000);
    //   return;
    // }

    let query = {
      query: {
        url: url,
        $select: ["_id"],
      },
    };

    if (this.state.pageId !== 0) {
      query.query["_id"] = { $ne: this.state.pageId };
    }

    let existingPages = await callGetLandingPagesUsingQuery(query);
    let eData = existingPages.data || [];

    if (eData.length > 0) {
      url = url + "-" + getUniqueId();
    }

    let scriptData = this.state.scriptData || {};

    if (!scriptData.pageTitle) {
      scriptData.pageTitle = this.state.name;
    }

    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      description: this.state.description,
      url: url,
      content_html: this.state.contentHtml,
      content_object: this.state.contentObject,
      content_type: this.state.contentType,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
      split_pages: this.state.splitPages,
      sub_pages: this.state.subPageList,
      script_data: scriptData,
      page_id: this.state.landingPageId,
      rules: this.state.rules,
      theme: {
        enabled: this.state.useTheme,
        theme: this.state.selectedTheme,
        showHeader: this.state.showHeader,
        showFooter: this.state.showFooter,
        changeDefault: this.state.changeDefaultValues,
      },
    };
    /*eslint-enable*/

    if (this.state.pageId !== 0) {
      /*eslint-disable*/
      let [err, data] = await toPromise(
        callUpdateLandingPage(this.state.pageId, apiData)
      );

      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Landing Page updated", 4000);
        if (this.props.fromPopup) {
          this.props.onCreate();
        }
      }

      this.setState({ pageId: this.state.defaultPageId }, () => {
        this.getLandingPageInfo();
      });
    } else {
      apiData["type"] = this.state.type;
      apiData["sub_id"] = "";
      apiData["unique_id"] = getUniqueId();
      apiData["sub_pages"] = this.state.subPageList;

      let id = "";
      if (this.state.type === "sales") {
        id = config.editorTemplateIds.salesLandingPage;
      }
      if (this.state.type === "optin") {
        id = config.editorTemplateIds.optinLandingPage;
      }
      if (this.state.type === "thankyou") {
        id = config.editorTemplateIds.thankYouLandingPage;
      }

      let landingTemplate = {};
      if (id) {
        let editorData = await callEmailTemplateById(id);
        if (editorData.data._id) {
          landingTemplate = editorData.data;
          let draftId = await this.saveDraft(
            landingTemplate.html,
            landingTemplate.editor_content
          );
          apiData["draft_id"] = draftId;
        }
      }

      apiData["content_html"] = landingTemplate.html || "";
      apiData["content_object"] = landingTemplate.editor_content || {};

      let [err, data] = await toPromise(callCreateLandingPage(apiData));
      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        if (this.state.subPageList.length > 0) {
          let newApiData = [];
          for (let i = 0; i < this.state.subPageList.length; i++) {
            const element = this.state.subPageList[i];
            let template = await this.prepareSubPage(element, data._id);
            newApiData.push(template);
          }
          await toPromise(callCreateLandingPage(newApiData));
        }
        showNotification("success", "Landing Page created", 4000);
        this.resetProps();
        this.props.history.replace(
          `/admin/add-landing-page?id=${data._id}&tab=content`
        );
        window.location.reload();
      }
    }
  }

  onSelectType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  toggleUseTheme = (check) => {
    this.setState({ useTheme: check });
  };

  toggleChangeDefaultValues = (check) => {
    this.setState({ changeDefaultValues: check });
  };

  toggleShowHeader = (check) => {
    this.setState({ showHeader: check });
  };

  toggleShowFooter = (check) => {
    this.setState({ showFooter: check });
  };

  renderDetailsTab() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            {this.state.pageId === 0 && (
              <>
                <div className="gap20" />
                <div className="radioBHolder">
                  <p>Type</p>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      key={this.state.keySelectType}
                      onCheckChange={this.onSelectType.bind(this)}
                      labelList={[
                        { name: "Sales", value: "sales" },
                        { name: "Optin", value: "optin" },
                        { name: "Thank You", value: "thankyou" },
                        { name: "Other", value: "other" },
                      ]}
                      id={"type"}
                      selectedList={[this.state.type]}
                      selectType={"value"}
                    />
                  </div>
                </div>
              </>
            )}
            <div
              className={
                this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
              }>
              <label>Name</label>
              <input
                type="text"
                name="name"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.name}
                // onBlur={(event) => this.handleOnBlurName(event)}
              />
            </div>
            <div className="gap20" />
            {this.state.pageId !== 0 && (
              <>
                <div
                  className={
                    this.state.url !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Url</label>
                  <input
                    type="text"
                    name="url"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.url}
                  />
                </div>
                <div className="gap10" />
                {this.state.url.length > 0 && (
                  <label
                    style={{
                      color: "gray",
                      fontSize: 14,
                    }}>
                    {this.getPreviewLink()}
                  </label>
                )}
                <div className="gap10" />
                <div
                  className={
                    this.state.landingPageId !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Landing Page ID</label>
                  <input
                    type="text"
                    name="landingPageId"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.landingPageId}
                  />
                </div>
                <div className="gap20" />
                <Checkbox
                  label={"Use Website Theme"}
                  isChecked={this.state.useTheme}
                  handleCheckboxChange={this.toggleUseTheme}
                  key={this.state.keyUseTheme}
                />
                <div className="gap30" />
                {this.state.useTheme && (
                  <>
                    <Checkbox
                      label={"Change Default Values"}
                      isChecked={this.state.changeDefaultValues}
                      handleCheckboxChange={this.toggleChangeDefaultValues}
                      key={this.state.keyChangeDefaultValues}
                    />
                    <div className="gap20" />
                    {this.state.changeDefaultValues && (
                      <>
                        <div className="radioBHolder">
                          <p>Select Theme</p>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              key={this.state.keySelectedTheme}
                              onCheckChange={this.onSelectType.bind(this)}
                              labelList={[
                                { name: "Theme 1", value: "simple" },
                                { name: "Theme 2", value: "theme2" },
                              ]}
                              id={"selectedTheme"}
                              selectedList={[this.state.selectedTheme]}
                              selectType={"value"}
                            />
                          </div>
                        </div>
                        <div className="gap20" />
                        <Checkbox
                          label={"Show Header"}
                          isChecked={this.state.showHeader}
                          handleCheckboxChange={this.toggleShowHeader}
                          key={this.state.keyHeader}
                        />
                        <div className="gap20" />
                        <Checkbox
                          label={"Show Footer"}
                          isChecked={this.state.showFooter}
                          handleCheckboxChange={this.toggleShowFooter}
                          key={this.state.keyFooter}
                        />
                        <div className="gap20" />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {this.state.pageId === 0 && (
              <>
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <div className="radioBHolder">
                      <p>Add Additional Pages</p>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keySelectSubType}
                          onCheckChange={this.onSelectType.bind(this)}
                          labelList={[
                            { name: "Sales", value: "sales" },
                            { name: "Optin", value: "optin" },
                            { name: "Thank You", value: "thankyou" },
                            { name: "Other", value: "other" },
                          ]}
                          id={"subType"}
                          selectedList={[this.state.subType]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                    <div className="gap10" />
                    <button
                      className="btn btn-default btn-sm"
                      onClick={() => this.onPressAddSubPage()}>
                      Add Now
                    </button>
                    <div className="gap10" />
                    {this.state.subPageList.length > 0 && (
                      <>
                        <div className="gap10" />
                        <h5>Added Sub Pages</h5>
                        <div className="gap10" />
                        <div className="gap10" />
                        <SortableSubPageList
                          items={this.state.subPageList}
                          onSortEnd={this.onSortEndSubPageList}
                          onPressRemove={this.onPressRemoveSubPage.bind(this)}
                        />
                        <div className="gap20" />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  onPressSplitTest() {
    $(
      ".modalAlertOverlay.createPageOverlay,.modalAlert.createPageAlert"
    ).fadeIn(200);
  }

  onClickDeleteTest() {
    let pages = this.state.splitPages.filter(
      (d) => d.id !== this.state.selectedSplitPage
    );
    this.setState({ splitPages: pages, selectedSplitPage: "original" }, () => {
      this.onPressSave();
    });
  }

  onClickEditTest() {
    let name = "";
    let pages = this.state.splitPages.filter(
      (d) => d.id === this.state.selectedSplitPage
    );
    if (pages.length > 0) {
      name = pages[0].name;
    }
    this.setState({ splitPageName: name }, () => {
      $(
        ".modalAlertOverlay.settingPageModalOverlay,.modalAlert.settingPageModal"
      ).fadeIn(200);
    });
  }

  onClickYesCreatePageAlert() {
    let html = this.state.contentHtml;
    let object = this.state.contentObject;
    let draftId = this.state.draftId;
    let draftImage = this.state.draftImage;
    let pages = this.state.splitPages;

    let id = getUniqueId();
    pages.push({
      id: id,
      html: html,
      draftId: draftId,
      draftImage: draftImage,
      name: `Split Page - ${id}`,
      object: getUpdatedEditorContent(object, html),
    });

    this.setState(
      {
        splitPages: pages,
        pageContent: Math.random(),
        contentHtml: html,
        selectedSplitPage: id,
        contentObject: getUpdatedEditorContent(object, html),
      },
      () => {
        $(
          ".modalAlertOverlay.createPageOverlay,.modalAlert.createPageAlert"
        ).fadeOut(200);
        this.onPressSave();
      }
    );
  }

  onClickNoCreatePageAlert = () => {
    $(
      ".modalAlertOverlay.createPageOverlay,.modalAlert.createPageAlert"
    ).fadeOut(200);
  };

  renderConfirmationPageAlert() {
    return (
      <div>
        <div className="modalAlertOverlay createPageOverlay" />
        <div className="modalAlert createPageAlert">
          <p>Create a new Split Test?</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoCreatePageAlert.bind(this)}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesCreatePageAlert.bind(this)}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelFromSettingPageModal() {
    $(
      ".modalAlertOverlay.settingPageModalOverlay,.modalAlert.settingPageModal"
    ).fadeOut(200);
  }

  onClickUpdateFromSettingPageModel() {
    let pages = this.state.splitPages;
    pages.forEach((element) => {
      if (element.id === this.state.selectedSplitPage) {
        element.name = this.state.splitPageName;
      }
    });
    $(
      ".modalAlertOverlay.settingPageModalOverlay,.modalAlert.settingPageModal"
    ).fadeOut(200);
    this.setState({ splitPages: pages, splitPageName: "" }, () => {
      this.onPressSave();
    });
  }

  renderSettingPageModal() {
    let selectedSplitPage = this.state.selectedSplitPage;
    if (!selectedSplitPage) {
      return;
    }
    return (
      <div>
        <div className="modalAlertOverlay settingPageModalOverlay" />
        <div className="modalAlert settingPageModal">
          <div className="clearfix">
            <div className="pull-left">
              <p>Settings</p>
            </div>
          </div>

          <div className="container-fluid">
            <div
              className={
                this.state.splitPageName !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Name</label>
              <input
                type="text"
                name="splitPageName"
                value={this.state.splitPageName}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20" />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromSettingPageModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickUpdateFromSettingPageModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderPageContentTab() {
    return (
      <>
        <div className="container-fluid">
          <PageContent
            key={this.state.pageContent}
            title={this.state.name}
            screen={"add-landing-page"}
            html={this.state.contentHtml}
            content={this.state.contentObject}
            onChangeData={this.onChangeContent.bind(this)}
            pageName={"addLandingPage"}
            onClickAlert={this.onPressSave.bind(this)}
            previewType={"mp-preview"}
            showContent={true}
            previewNote={false}
            draftId={this.state.draftId}
            draftImage={this.state.draftImage}
            editorType={this.state.editorType}
          />
        </div>
        {this.state.pageId === this.state.defaultPageId && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                {this.state.stats.totalVisits > 0 && (
                  <div>
                    <div className="gap20" />
                    <label>
                      Total Visits: {this.state.stats.totalVisits}
                    </label>{" "}
                    <br />
                    <label>
                      Unique Visits: {this.state.stats.uniqueVisits}
                    </label>
                    <br />
                    <label>Conversions: {this.state.stats.conversions}</label>
                    <br />
                  </div>
                )}
                <div className="gap10" />
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.onPressSplitTest()}>
                  Add Split Test
                </button>
                <div className="gap10" />
                <div
                  className={
                    this.state.selectedSplitPage !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Page</label>
                  <select
                    className="actionSelect"
                    name="selectedSplitPage"
                    value={this.state.selectedSplitPage}
                    onChange={(event) => this.handlePageChange(event)}>
                    <option value="original">Original</option>
                    {this.state.splitPages.map((i) => (
                      <option value={i.id}>{i.name}</option>
                    ))}
                  </select>
                </div>
                {this.state.selectedSplitPage !== "original" && (
                  <>
                    <button
                      onClick={this.onClickEditTest.bind(this)}
                      className="btn btn-default btn-sm margin-right-10 border-0">
                      <i className="fa fa-cog" />
                    </button>
                    <button
                      onClick={this.onClickDeleteTest.bind(this)}
                      className="btn btn-danger btn-sm margin-right-10 border-0">
                      <i className="fa fa-trash" />
                    </button>
                  </>
                )}
                <div className="gap10" />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  onClickScriptSubmit = (data) => {
    /*eslint-disable*/
    let updateData = {
      script_data: data,
    };
    /*eslint-enable*/
    callUpdateLandingPage(this.state.pageId, updateData).then((d) => {
      showNotification("success", "Landing Page updated", 4000);
      this.props.history.replace(
        `/admin/add-landing-page?id=${this.state.defaultPageId}&tab=content`
      );
      window.location.reload();
    });
  };

  onClickSubmitFunnelSettings() {
    let scriptData = this.state.scriptData;
    scriptData.defaultFbPixel = this.state.defaultFbPixel;
    scriptData.fbPixel = this.state.fbPixel;
    scriptData.leadPixel = this.state.leadPixel;
    scriptData.purchasePixel = this.state.purchasePixel;

    scriptData.googleLead = this.state.googleLead;
    scriptData.googlePurchase = this.state.googlePurchase;
    scriptData.googleTag = this.state.googleTag;

    /*eslint-disable*/
    let updateData = {
      pixel_id: this.state.pixelId,
      google_tag_id: this.state.googleTagId,
      script_data: scriptData,
    };
    /*eslint-enable*/
    callUpdateLandingPage(this.state.pageId, updateData).then((d) => {
      showNotification("success", "Landing Page updated", 4000);
      this.props.history.replace(
        `/admin/add-landing-page?id=${this.state.defaultPageId}&tab=content`
      );
      window.location.reload();
    });
  }

  renderSettingsTab() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <ScriptPopup
              key={this.state.keyScriptPopup}
              script={this.state.scriptData}
              onSubmit={this.onClickScriptSubmit}
              cmpType={"page"}
            />
          </div>
        </div>
      </div>
    );
  }

  onSelectRadioSelect(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  renderFunnelSettingsTab() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="profileTab">
            <div className="modalHeaderTabs">
              {this.renderFunnelTabOptions()}
            </div>
          </div>

          {this.state.selectedFunnelTab === "facebook" && (
            <div
              className={
                this.state.selectedFunnelTab === "facebook"
                  ? "active for-tab1"
                  : "for-tab1"
              }>
              <div className="container-fluid">
                <div className="gap20" />
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <label>Add your Facebook pixel to this page</label>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectRadioSelect.bind(this)}
                          labelList={[
                            { name: "Yes", value: "yes" },
                            { name: "No", value: "no" },
                          ]}
                          key={this.state.keyRadioSelect}
                          id={"fbPixel"}
                          selectType={"value"}
                          selectedList={[this.state.fbPixel]}
                        />
                      </div>
                      <div className="gap10" />
                    </div>
                  </div>
                </div>

                {this.state.fbPixel === "yes" && (
                  <>
                    <>
                      <div
                        className={
                          this.state.pixelId !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Facebook Pixel ID</label>
                        <input
                          type="text"
                          name="pixelId"
                          value={this.state.pixelId}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                    </>

                    <div className="gap10" />
                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <label>Track this page as a lead</label>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectRadioSelect.bind(
                                this
                              )}
                              labelList={[
                                { name: "Yes", value: "yes" },
                                { name: "No", value: "no" },
                              ]}
                              key={this.state.keyRadioSelect}
                              id={"leadPixel"}
                              selectType={"value"}
                              selectedList={[this.state.leadPixel]}
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <label>Track this page as a purchase</label>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectRadioSelect.bind(
                                this
                              )}
                              labelList={[
                                { name: "Yes", value: "yes" },
                                { name: "No", value: "no" },
                              ]}
                              key={this.state.keyRadioSelect}
                              id={"purchasePixel"}
                              selectType={"value"}
                              selectedList={[this.state.purchasePixel]}
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                    </div>

                    <div className="gap10" />
                  </>
                )}
              </div>
            </div>
          )}

          {this.state.selectedFunnelTab === "google" && (
            <div
              className={
                this.state.selectedFunnelTab === "google"
                  ? "active for-tab2"
                  : "for-tab2"
              }>
              <div className="container-fluid">
                <div className="gap20" />
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <label>Add your Google Tag to this page</label>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectRadioSelect.bind(this)}
                          labelList={[
                            { name: "Yes", value: "yes" },
                            { name: "No", value: "no" },
                          ]}
                          key={this.state.keyRadioSelect}
                          id={"googleTag"}
                          selectType={"value"}
                          selectedList={[this.state.googleTag]}
                        />
                      </div>
                      <div className="gap10" />
                    </div>
                  </div>
                </div>

                {this.state.googleTag === "yes" && (
                  <>
                    <>
                      <div
                        className={
                          this.state.googleTagId !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Google Tag ID</label>
                        <input
                          type="text"
                          name="googleTagId"
                          value={this.state.googleTagId}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                    </>

                    <div className="gap10" />
                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <label>Track this page as a lead</label>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectRadioSelect.bind(
                                this
                              )}
                              labelList={[
                                { name: "Yes", value: "yes" },
                                { name: "No", value: "no" },
                              ]}
                              key={this.state.keyRadioSelect}
                              id={"googleLead"}
                              selectType={"value"}
                              selectedList={[this.state.googleLead]}
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <label>Track this page as a purchase</label>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectRadioSelect.bind(
                                this
                              )}
                              labelList={[
                                { name: "Yes", value: "yes" },
                                { name: "No", value: "no" },
                              ]}
                              key={this.state.keyRadioSelect}
                              id={"googlePurchase"}
                              selectType={"value"}
                              selectedList={[this.state.googlePurchase]}
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                    </div>

                    <div className="gap10" />
                  </>
                )}
              </div>
            </div>
          )}

          <div className="gap10" />
          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onClickSubmitFunnelSettings()}>
              Save
            </button>
          </div>
          <div className="gap10" />
        </div>
      </div>
    );
  }

  insertUrlParam(key, value) {
    if (window.history.pushState) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      let newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }

  onClickTab(tab) {
    this.insertUrlParam("tab", tab);
    let tabOptions = this.state.tabOptions;
    tabOptions.forEach((element) => {
      element.selected = element.key === tab;
    });
    this.setState({
      tabOptions,
      selectedTab: tab,
    });
  }

  onClickFunnelTab(tab) {
    let tabFunnelOptions = this.state.tabFunnelOptions;
    tabFunnelOptions.forEach((element) => {
      element.selected = element.key === tab;
    });
    this.setState({
      tabFunnelOptions,
      selectedFunnelTab: tab,
    });
  }

  renderTabOptions() {
    let tabOptions = this.state.tabOptions;
    let returnVals = [];
    for (let i = 0; i < tabOptions.length; i++) {
      const element = tabOptions[i];
      const dataTab = `.for-tab${i + 1}`;
      /*eslint-disable*/
      returnVals.push(
        <a
          className={element.selected ? "active" : ""}
          onClick={() => this.onClickTab(element.key)}
          data-for={dataTab}>
          {element.label}
        </a>
      );
      /*eslint-enable*/
    }
    return returnVals;
  }

  renderFunnelTabOptions() {
    let tabOptions = this.state.tabFunnelOptions;
    let returnVals = [];
    for (let i = 0; i < tabOptions.length; i++) {
      const element = tabOptions[i];
      const dataTab = `.for-tab${i + 1}`;
      /*eslint-disable*/
      returnVals.push(
        <a
          className={element.selected ? "active" : ""}
          onClick={() => this.onClickFunnelTab(element.key)}
          data-for={dataTab}>
          {element.label}
        </a>
      );
      /*eslint-enable*/
    }
    return returnVals;
  }

  async onPressSaveSetting() {
    let subPageInfoList = this.state.subPageInfoList;
    let subPages = [];
    subPageInfoList.forEach((element) => {
      subPages.push({
        type: element.type,
        id: element.unique_id,
      });
    });

    /*eslint-disable*/
    await callUpdateLandingPage(this.state.defaultPageId, {
      sub_pages: subPages,
    });
    /*eslint-enable*/

    this.setState(
      { pageId: this.state.defaultPageId, showSetting: false },
      () => {
        this.getLandingPageInfo();
      }
    );
  }

  onClickSetting() {
    this.setState({ showSetting: !this.state.showSetting });
  }

  onClickAddNewPage() {
    this.setState({ addNewSubPage: !this.state.addNewSubPage });
  }

  onChangeEmailContent(html, content) {
    this.setState(
      {
        emailContent: content,
        emailHtml: html,
      },
      () => {
        this.onPressSaveNotification();
      }
    );
  }

  onChangeNotiEmailContent(html, content) {
    this.setState({
      emailNotiContent: content,
      emailNotiHtml: html,
    });
  }

  renderDays() {
    var returnVals = [];
    for (let i = 1; i <= 31; i++) {
      let day = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={day} key={day}>
          {day}
        </option>
      );
    }
    return returnVals;
  }

  renderMonths() {
    var returnVals = [];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let i = 1; i <= 12; i++) {
      let month = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={month} key={month}>
          {monthNames[i - 1]}
        </option>
      );
    }
    return returnVals;
  }

  renderYears() {
    var returnVals = [
      <option value={"2022"} key={"2022"}>
        {"2022"}
      </option>,
      <option value={"2023"} key={"2023"}>
        {"2023"}
      </option>,
      <option value={"2024"} key={"2024"}>
        {"2024"}
      </option>,
      <option value={"2025"} key={"2025"}>
        {"2025"}
      </option>,
    ];
    return returnVals;
  }

  renderHours() {
    var returnVals = [];
    for (let i = 1; i <= 12; i++) {
      let hour = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={hour} key={hour}>
          {hour}
        </option>
      );
    }
    return returnVals;
  }

  renderMins() {
    var returnVals = [];
    for (let i = 0; i <= 45; i = i + 15) {
      let min = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={min} key={min}>
          {min}
        </option>
      );
    }
    return returnVals;
  }

  async onPressSaveNotification() {
    /*eslint-disable*/

    let notification = {
      type: this.state.notiType,
      email_id: "",
      event_id: "",
      event_date: "",
      event_time: "",
      timezone: this.state.timezone,
    };
    /*eslint-enable*/

    if (this.state.notiType === "email") {
      if (!this.state.emailId) {
        let response = await callCreateEmail({
          name: this.state.emailSubject,
          title: this.state.emailSubject,
          content: this.state.emailContent,
          html: this.state.emailHtml,
        });
        notification["email_id"] = response._id;
      } else {
        await callUpdateEmail(this.state.emailId, {
          name: this.state.emailSubject,
          title: this.state.emailSubject,
          content: this.state.emailContent,
          html: this.state.emailHtml,
        });
        notification["email_id"] = this.state.emailId;
      }
    } else if (this.state.notiType === "event") {
      let newDate = moment(
        `${this.state.selectedYear}/${this.state.selectedMonth}/${this.state.selectedDay}`,
        "YYYY/MM/DD",
        true
      );
      let newTime = `${this.state.selectedHour}:${this.state.selectedMins} ${this.state.selectedTimeType}`;

      let fDate = `${newDate.format("YYYY-MM-DD")}T${newTime}`;
      let fTime = moment(fDate, "YYYY-MM-DDThh:mm A");

      notification["event_date"] = fTime;
      notification["event_time"] = newTime;
      notification["event_id"] = this.state.eventId;
    }

    await callUpdateLandingPage(this.state.pageId, { notification });
    showNotification("success", "Notification updated!");
  }

  onSelectTagType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onChangeTagValue(value) {
    this.setState({
      tagValue: value,
    });
  }

  onSelectTagValue(value) {
    this.setState({
      tagSelected: value,
    });
  }

  async onClickCreateTag() {
    let tagName = this.state.tagName.trim().toLowerCase();
    if (tagName.length === 0) {
      showNotification("error", "Tag name is required", 5000);
      return;
    }
    let existingTags = this.state.tags.filter((d) => {
      return d.name.toLowerCase() === tagName;
    });
    if (existingTags.length > 0) {
      showNotification("error", "Tag already exists", 4000);
      return;
    }
    // call api to create system tag
    /*eslint-disable*/
    let apiData = {
      name: tagName,
      description: tagName,
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
      events: [],
      notifications: [],
      product_list: [],
      generic_tag_list: [],
      zoom_meeting: {
        user_id: "",
        meeting_id: "",
      },
      type: "automated",
      created_from: "landing-page",
    };
    /*eslint-enable*/
    let newTag = await callCreateSystemTag(apiData);
    if (newTag._id) {
      // call api get system tags
      let tags = await callGetSystemTags();
      this.setState(
        {
          tagName: "",
          tags: tags.data,
          tagType: "existing",
          keyTag: Math.random(),
          keyTagType: Math.random(),
          tagValue: this.state.tagName,
        },
        () => {
          this.onClickAddRule();
        }
      );
    }
  }

  onSelectSendTime(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  renderSendTime() {
    return (
      <>
        <div className="gap10" />
        <div className="radioBHolder">
          <p>Send Time</p>
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onSelectSendTime.bind(this)}
              labelList={[
                {
                  name: "Immediately",
                  value: "send",
                },
                { name: "Specified Time", value: "time" },
              ]}
              id={"sendTimeType"}
              selectedList={[this.state.sendTimeType]}
              selectType={"value"}
              key={this.state.keySendTime}
            />
          </div>
        </div>
        {this.state.sendTimeType === "time" && (
          <>
            <div className="row">
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedHour !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Hour</label>
                  <select
                    name="selectedHour"
                    value={this.state.selectedHour}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderHours()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedMins !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Minute</label>
                  <select
                    name="selectedMins"
                    value={this.state.selectedMins}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderMins()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedTimeType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Type</label>
                  <select
                    name="selectedTimeType"
                    value={this.state.selectedTimeType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  getFirstName() {
    return "{{first_name}}";
  }

  getNoOfMessages() {
    let noOf = this.state.noOfMessages;
    return noOf;
  }

  handleMessageChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const isGMSMessage = checkGMSMessage(value);
    const limit = isGMSMessage ? 140 : 67;

    let noOfMessages = Math.ceil(value.length / limit);

    this.setState({
      [name]: value,
      isGMSMessage: isGMSMessage,
      noOfMessages,
    });
  }

  async prepareRules(rawRules) {
    let rules = [];
    let atSignup = rawRules.filter((d) => d.type === "at_signup");
    let onSpecificDay = rawRules.filter((d) => d.type === "on_specific_day");

    onSpecificDay.sort((a, b) => {
      return parseInt(a.day) - parseInt(b.day);
    });

    rules = rules.concat(atSignup);
    rules = rules.concat(onSpecificDay);

    let eRules = [];

    for (let i = 0; i < rules.length; i++) {
      let element = rules[i];
      if (element.email_linked) {
        let response = await callGetEmails(
          {
            query: {
              _id: element.email_linked,
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.emailName = response.data[0].name;
        }
      }
      if (element.sms_linked) {
        let response = await callGetTextMessagesByQuery(
          {
            query: {
              _id: element.sms_linked,
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.smsName = response.data[0].name;
        }
      }
      eRules.push(element);
    }

    return eRules;
  }

  async checkActionType(tagId) {
    if (
      this.state.actionType !== "add-email" &&
      this.state.actionType !== "add-notification" &&
      this.state.actionType !== "add-atq" &&
      this.state.actionType !== "add-sms"
    ) {
      if (this.state.tagValue.trim().length === 0) {
        showNotification("error", "Tag is required", 4000);
        return;
      }
    }

    let actionType = this.state.actionType;
    let emailId = "";
    let notiId = "";
    let queId = "";
    let smsId = "";
    let tagIdN = "";
    let tagValue = "";

    if (this.state.actionType === "add-email") {
      let dOpe = new ChallengeOperations();
      /*eslint-disable*/
      let response = await dOpe.createChallengeRuleEmail(
        this.state.name,
        this.state.onSpecificDay,
        this.state.emailNotiName,
        this.state.emailNotiContent,
        this.state.emailNotiHtml,
        {
          send_time_type: this.state.sendTimeType,
          selected_hour: this.state.selectedHour,
          selected_mins: this.state.selectedMins,
          selected_time_type: this.state.selectedTimeType,
        }
      );
      /*eslint-enable*/
      tagValue = response.tagValue;
      tagIdN = response.tagId;
      emailId = response.emailId;
    } else if (this.state.actionType === "add-sms") {
      let dOpe = new ChallengeOperations();
      /*eslint-disable*/
      let response = await dOpe.createChallengeRuleSMS(
        this.state.name,
        this.state.onSpecificDay,
        this.state.smsName,
        this.state.smsMessage,
        {
          send_time_type: this.state.sendTimeType,
          selected_hour: this.state.selectedHour,
          selected_mins: this.state.selectedMins,
          selected_time_type: this.state.selectedTimeType,
        }
      );
      /*eslint-enable*/
      tagValue = response.tagValue;
      tagIdN = response.tagId;
      smsId = response.smsId;
    } else {
      tagIdN = tagId[0]._id;
      tagValue = this.state.tagValue;
    }

    return { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId };
  }

  async onClickAddRule() {
    let rules = this.state.rules;
    let tagId = this.state.tags.filter((d) => {
      return d.name === this.state.tagValue;
    });
    if (!tagId) {
      showNotification("error", "Tag not found", 4000);
      return;
    }
    if (this.state.ruleType.trim().length === 0) {
      showNotification("error", "Rule type is required", 4000);
      return;
    }
    if (this.state.ruleType === "on_specific_day") {
      if (this.state.onSpecificDay.trim().length === 0) {
        showNotification("error", "Enter Day is required", 4000);
        return;
      }

      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: "on_specific_day",
        day: this.state.onSpecificDay,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    } else if (this.state.ruleType === "at_signup") {
      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: this.state.ruleType,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    }

    let newRules = await this.prepareRules(this.state.rules);

    this.setState(
      {
        rules: newRules,
        tagValue: "",
        keyTag: Math.random(),
        emailNotiContent: {},
        emailNotiHtml: "",
        emailNotuName: "",
        smsName: "",
        smsMessage: "",
      },
      () => {
        this.onPressSave();
      }
    );
  }

  onClickDeleteRule(index) {
    let rules = this.state.rules;
    rules.splice(index - 1, 1);

    this.setState({ rules: rules }, () => {
      this.onPressSave();
    });
  }

  getSystemTagName(element) {
    if (element.emailName) {
      return element.emailName;
    }
    if (element.questionName) {
      return element.questionName;
    }
    if (element.notificationTitle) {
      return element.notificationTitle;
    }
    if (element.smsName) {
      return element.smsName;
    }
    let tagList = this.state.tags.filter((d) => d._id === element.tag_id);
    if (tagList.length > 0) {
      return tagList[0].name;
    }
    return "";
  }

  onClickEditEmail = (email) => {
    let showBack = "y";
    this.props.history.push(
      `/admin/edit-email?eid=${email._id}&back=${showBack}`
    );
  };

  onClickEditSMS = async (smsId) => {
    this.props.history.push(`/admin/add-sms?id=${smsId}`);
  };

  renderRules() {
    let rules = this.state.rules;
    let returnVals = [];
    let i = 0;

    const ButtonDelete = (props) => (
      <button
        onClick={this.onClickDeleteRule.bind(this, props.i)}
        className="btn btn-danger btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-trash" />
      </button>
    );

    const ButtonEdit = (props) => (
      <button
        onClick={props.onClick}
        className="btn btn-default btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-pencil" />
      </button>
    );

    if (rules.length > 0) {
      rules.forEach((element) => {
        i++;
        let labelTag = "";
        switch (element.action) {
          case "add-tag":
            labelTag = "Add A Tag";
            break;
          case "add-email":
            labelTag = "Add Email";
            break;
          case "add-sms":
            labelTag = "Add SMS";
            break;
          default:
            labelTag = "Inactivate A Tag";
            break;
        }
        if (element.type === "on_specific_day") {
          returnVals.push(
            <div key={i}>
              On Day : {element.day}, {labelTag} :{" "}
              {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
              {element.sms_linked && (
                <ButtonEdit
                  onClick={this.onClickEditSMS.bind(this, element.sms_linked)}
                />
              )}
            </div>
          );
        } else if (element.type === "at_signup") {
          returnVals.push(
            <div key={i}>
              At Signup, {labelTag} : {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
              {element.sms_linked && (
                <ButtonEdit
                  onClick={this.onClickEditSMS.bind(this, element.sms_linked)}
                />
              )}
            </div>
          );
        }
      });
    }
    return returnVals;
  }

  renderNotificationTab() {
    return (
      <div className="container-fluid">
        {(this.state.notiType === "email" ||
          this.state.notiType === "event") && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="radioBHolder">
                <p>Notification Type</p>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    key={this.state.keyNotiType}
                    onCheckChange={this.onSelectType.bind(this)}
                    labelList={[
                      { name: "None", value: "none" },
                      { name: "Send Email(s)", value: "email" },
                      { name: "Register For Event", value: "event" },
                    ]}
                    id={"notiType"}
                    selectedList={[this.state.notiType]}
                    selectType={"value"}
                  />
                </div>
              </div>
              <div className="gap20" />
              {this.state.notiType === "email" && (
                <div>
                  <label>
                    To instantly send an email you can add that below.
                  </label>
                  <br />
                  <label>
                    If you would like to send more than one email then you can
                    scroll below to add additional rules.
                  </label>
                  <div
                    className={
                      this.state.emailSubject !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Email Subject</label>
                    <input
                      type="text"
                      className="challengeName"
                      name="emailSubject"
                      value={this.state.emailSubject}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                  <PageContent
                    key={this.state.keyContent}
                    title={this.state.emailSubject || "email-content"}
                    displayMode={"email"}
                    previewType={"iframe"}
                    screen={"add-landing-notification-email"}
                    html={this.state.emailHtml}
                    content={this.state.emailContent}
                    editorType={"unlayer"}
                    onChangeData={this.onChangeEmailContent.bind(this)}
                    pageName={"addLandingNotificationEmail"}
                  />
                  <div className="gap20" />
                </div>
              )}
              {this.state.notiType === "event" && (
                <>
                  <label>
                    Select an event and time
                    <br></br>
                    <br></br>Users will automatically be registered for the
                    event you select and the specified day and time. All users
                    will be registered regardless of the spots available.
                    <br></br>
                    <br></br>Note: When users register here they will not be
                    added to your Google calendar.
                  </label>
                  <div className="gap20" />

                  <div className="row">
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.eventId !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Event</label>
                        <select
                          name="eventId"
                          value={this.state.eventId}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          {this.state.events.map((i) => (
                            <option value={i._id}>{i.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-sm-2">
                      <div
                        className={
                          this.state.selectedMonth !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Month</label>
                        <select
                          name="selectedMonth"
                          value={this.state.selectedMonth}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          {this.renderMonths()}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-2">
                      <div
                        className={
                          this.state.selectedDay !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Day</label>
                        <select
                          name="selectedDay"
                          value={this.state.selectedDay}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          {this.renderDays()}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>

                    <div className="col-sm-2">
                      <div
                        className={
                          this.state.selectedYear !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Year</label>
                        <select
                          name="selectedYear"
                          value={this.state.selectedYear}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          {this.renderYears()}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-2">
                      <div
                        className={
                          this.state.selectedHour !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Hour</label>
                        <select
                          name="selectedHour"
                          value={this.state.selectedHour}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          {this.renderHours()}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-2">
                      <div
                        className={
                          this.state.selectedMins !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Minute</label>
                        <select
                          name="selectedMins"
                          value={this.state.selectedMins}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          {this.renderMins()}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-2">
                      <div
                        className={
                          this.state.selectedTimeType !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Type</label>
                        <select
                          name="selectedTimeType"
                          value={this.state.selectedTimeType}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                  <div className="gap10" />
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.timezone !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select a timezone</label>
                        <select
                          value={this.state.timezone}
                          name="timezone"
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="America/New_York">
                            Eastern (New York)
                          </option>
                          <option value="America/Chicago">
                            Central (Chicago)
                          </option>
                          <option value="America/Denver">
                            Mountain (Denver)
                          </option>
                          <option value="America/Phoenix">
                            Mountain [No DST] (Phoenix)
                          </option>
                          <option value="America/Los_Angeles">
                            Pacific (Los Angeles)
                          </option>
                          <option value="America/Anchorage">Alaska</option>
                          <option value="America/Adak">Hawaii-Aleutian</option>
                          <option value="Pacific/Honolulu">
                            Hawaii-Aleutian (No DST)
                          </option>
                          <option value="Asia/Calcutta">
                            India (Calcutta)
                          </option>
                          <option value="Europe/Amsterdam">
                            Europe (Amsterdam)
                          </option>
                          <option value="US/Central">US (Central)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="gap20" />
                </>
              )}
              <div className="text-right container-fluid">
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressSaveNotification()}>
                  Save
                </button>
              </div>
              <div className="gap20" />
            </div>
          </div>
        )}
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Add Rules</h5>
            <div className="gap20" />
            <div className="row">
              <div className="col-sm-4">
                <div
                  className={
                    this.state.ruleType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Rule Type</label>
                  <select
                    className="selectRuleTypeInput"
                    name="ruleType"
                    value={this.state.ruleType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="on_specific_day">On Day</option>
                    <option value="at_signup">At Signup</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
              {this.state.ruleType === "on_specific_day" && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.onSpecificDay !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Enter Day</label>
                    <input
                      type="text"
                      className="onDayInput"
                      name="onSpecificDay"
                      value={this.state.onSpecificDay}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.ruleType !== "" && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.actionType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Action</label>
                    <select
                      className="actionSelect"
                      name="actionType"
                      value={this.state.actionType}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="add-tag">Add A Tag</option>
                      <option value="add-email">Add an Email</option>
                      <option value="add-sms">Add SMS</option>
                    </select>
                  </div>
                  <div className="gap20" />
                </div>
              )}
            </div>
            <div className="gap20" />
            {this.state.actionType === "add-tag" && (
              <>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectTagType.bind(this)}
                    labelList={[
                      { name: "Select Existing Tag", value: "existing" },
                      { name: "Create New Tag", value: "new" },
                    ]}
                    id={"tagType"}
                    selectedList={[this.state.tagType]}
                    selectType={"value"}
                    key={this.state.keyTagType}
                  />
                </div>
                {this.state.tagType === "existing" && (
                  <div
                    className={
                      this.state.tagValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Search Tag</label>
                    <AutoSuggest
                      value={this.state.tagValue}
                      key={this.state.keyTag}
                      searchKey="name"
                      data={this.state.tags}
                      onChange={this.onChangeTagValue.bind(this)}
                      onSelect={this.onSelectTagValue.bind(this)}
                    />
                  </div>
                )}
                {this.state.tagType === "new" && (
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.tagName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Tag Name</label>
                        <input
                          type="text"
                          name="tagName"
                          value={this.state.tagName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="gap30" />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickCreateTag.bind(this)}>
                        Create
                      </button>
                    </div>
                  </div>
                )}
                {this.state.ruleType === "per_completed" && (
                  <p style={{ paddingTop: 10 }}>
                    Please make sure any tag that is added here is set to only
                    ever be added one time (you can set the days limit to 9999)
                    this way users will not get tags everytime they click the
                    completed boxes.
                  </p>
                )}
              </>
            )}

            {this.state.actionType === "add-email" && (
              <div>
                <div
                  className={
                    this.state.emailNotiName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Email Name</label>
                  <input
                    type="text"
                    className="challengeName"
                    name="emailNotiName"
                    value={this.state.emailNotiName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
                <PageContent
                  key={this.state.keyContent}
                  title={this.state.name}
                  displayMode={"email"}
                  previewType={"iframe"}
                  screen={"add-landing-page-notification-email"}
                  html={this.state.emailNotiHtml}
                  content={this.state.emailNotiContent}
                  editorType={"unlayer"}
                  onChangeData={this.onChangeNotiEmailContent.bind(this)}
                  pageName={"addLandingPageNotiEmail"}
                />
                <div className="gap10" />
                {this.renderSendTime()}
              </div>
            )}

            {this.state.actionType === "add-sms" && (
              <div>
                <div className="alert alert-info">
                  When sending automated sms messages as challenge rules please
                  be sure they are useful and specific and preferably something
                  the user will respond back to. For example a good message is
                  "Hey {this.getFirstName()}, thank you for completing XYZ. I
                  would really love to get your feedback on the course. Can you
                  please let me know what you thought about it?"
                  <br />
                  <br />A message that would be better sent as an app
                  notification would be something like: "Hey{" "}
                  {this.getFirstName()} It's day one of your challenge and you
                  can click here to view the content now." The customer is not
                  expected to respond to this message and it is more likely to
                  be viewed as spam via the phone carriers.
                </div>
                <div
                  className={
                    this.state.smsName !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Name</label>
                  <input
                    type="text"
                    name="smsName"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.smsName}
                  />
                </div>
                <div className="gap10" />
                <div
                  className={
                    this.state.smsMessage !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Message</label>
                  <textarea
                    style={{ height: "auto" }}
                    rows={5}
                    type="text"
                    name="smsMessage"
                    onChange={this.handleMessageChange.bind(this)}
                    value={this.state.smsMessage}
                  />
                </div>
                <div className="gap10" />
                <label>
                  {this.state.smsMessage.length} /{" "}
                  {this.state.isGMSMessage ? "140" : "67"}
                </label>
                {!this.state.isGMSMessage && (
                  <>
                    <div className="gap20" />
                    <label>
                      Note: Special characters detected. Message character limit
                      set to 67 characters.
                    </label>
                  </>
                )}
                {this.getNoOfMessages() > 1 && (
                  <>
                    <div className="gap20" />
                    <label className="text-primary">
                      This message has more characters than the limit. You can
                      still send it as a single message but will be charged for{" "}
                      {this.getNoOfMessages()} messages per user. This is due to
                      the network carrier fees etc.
                    </label>
                  </>
                )}
                <div className="gap20" />
                {this.renderSendTime()}
              </div>
            )}
            <div className="gap20" />
            <div className="text-right">
              <button
                className="btn btn-primary addRuleBtn"
                onClick={() => this.onClickAddRule()}>
                Add Rule
              </button>
            </div>
            <div className="gap20" />
            {this.state.rules.length > 0 && (
              <>
                <h5 className="noMargin">Added Rules</h5>
                <div className="gap20" />
                {this.state.isRuleLoading ? (
                  <div className="addedRule">Loading ...</div>
                ) : (
                  <div className="addedRule">{this.renderRules()}</div>
                )}

                <div className="gap20" />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loadingPage) {
      return <div id="loader"></div>;
    }
    let showTitle = true;
    if (this.props.fromPopup === true) {
      showTitle = false;
    }

    return (
      <div>
        {this.renderConfirmationPageAlert()}
        {this.renderSettingPageModal()}
        <div className="container-fluid">
          {showTitle && (
            <>
              <TitleBox
                title={
                  this.state.pageId === 0
                    ? "Add New Landing Page"
                    : "Edit Landing Page"
                }
                showBackBtn={this.state.pageId !== 0}
              />

              <div className="gap20" />
            </>
          )}
        </div>
        <div className="container-fluid">
          <div className="card">
            {this.state.pageId !== 0 && (
              <>
                {(!this.state.scriptData ||
                  !this.state.scriptData.socialMediaImage) && (
                  <div className="container-fluid">
                    <div className="gap20" />
                    <div
                      className="alert alert-info"
                      role="alert"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.onClickTab("settings");
                      }}>
                      This landing page doesnt have a social media image
                      assigned to it. Click here to add one now.
                    </div>
                  </div>
                )}
                {this.state.subPageInfo.length > 0 && (
                  <div className="container-fluid">
                    {/* <p>You are currently editing</p>
                    <div className="row" style={{ paddingLeft: 15 }}>
                      <select
                        name="pageId"
                        value={this.state.pageId}
                        onChange={(event) => this.handlePageInput(event)}>
                        {this.state.subPageInfo.map((i) => (
                          <option value={i._id} key={i._id}>
                            {i.type} | {i.name}
                          </option>
                        ))}
                      </select>
                      
                      <button
                        style={{ paddingLeft: 20 }}
                        title="Setting"
                        onClick={this.onClickSetting.bind(this)}
                        className="btn btn-default btn-sm border-0">
                        <i className="fa fa-cog" />
                      </button>                      
                    </div> */}
                    {/* <button
                      style={{ paddingLeft: 20 }}
                      title="Add New"
                      onClick={this.onClickAddNewPage.bind(this)}
                      className="btn btn-default btn-sm border-0 margin-right-10">
                      Add New Page
                    </button> */}

                    <div className="row">
                      {this.state.subPageInfo.map((i) => (
                        <div className="col-md-3">
                          <div className="card">
                            <div className="container-fluid">
                              <div className="gap20"></div>

                              <label>{i.name}</label>
                              <div>
                                <div className="gap10"></div>
                                {i.image &&
                                (i.imageStatus === "completed" ||
                                  i.image_status === "completed") ? (
                                  <img
                                    src={imageUrl(i.image)}
                                    class="btn-block pointer-click editorGalleryImage"
                                    alt="ImageUrl"
                                  />
                                ) : (
                                  <label style={{ color: "gray" }}>
                                    Image being generated
                                  </label>
                                )}
                                <div className="gap10"></div>
                              </div>
                              {this.state.pageId === i._id ? (
                                <button className="btn btn-success btn-sm btn-block">
                                  This Page Selected
                                </button>
                              ) : (
                                <button
                                  className="btn btn-default btn-sm btn-block"
                                  onClick={() => {
                                    this.checkPageInfo(i._id);
                                  }}>
                                  Select This Page
                                </button>
                              )}

                              <div className="gap20"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {this.state.subPageInfoList.length > 0 && (
                      <>
                        <VelocityTransitionGroup
                          enter={{ animation: "slideDown" }}
                          leave={{ animation: "slideUp" }}>
                          {this.state.showSetting && (
                            <>
                              <div className="gap20" />
                              <SortableSubPageList
                                items={this.state.subPageInfoList}
                                onSortEnd={this.onSortEndSubPageInfoList}
                                onPressRemove={this.onPressRemoveSubPageInfo.bind(
                                  this
                                )}
                              />
                              <div className="gap20" />
                              <button
                                className="btn btn-primary"
                                onClick={() => this.onPressSaveSetting()}>
                                Save
                              </button>
                            </>
                          )}
                        </VelocityTransitionGroup>
                      </>
                    )}
                  </div>
                )}
                <div className="container-fluid">
                  {/* {this.state.subPageInfo.length === 0 && ( */}
                  <button
                    style={{ paddingLeft: 10, marginTop: 10 }}
                    title="Add New"
                    onClick={this.onClickAddNewPage.bind(this)}
                    className="btn btn-default btn-sm">
                    Add New Sub Page
                  </button>
                  {/* )} */}
                  <VelocityTransitionGroup
                    enter={{ animation: "slideDown" }}
                    leave={{ animation: "slideUp" }}>
                    {this.state.addNewSubPage && (
                      <>
                        <div className="gap10" />
                        <div className="radioBHolder">
                          <p>Type</p>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              key={this.state.keySelectSubType}
                              onCheckChange={this.onSelectType.bind(this)}
                              labelList={[
                                { name: "Sales", value: "sales" },
                                { name: "Optin", value: "optin" },
                                { name: "Thank You", value: "thankyou" },
                                { name: "Other", value: "other" },
                              ]}
                              id={"subType"}
                              selectedList={[this.state.subType]}
                              selectType={"value"}
                            />
                          </div>
                        </div>
                        <div className="gap10" />
                        <button
                          className="btn btn-default btn-sm"
                          onClick={() => this.onPressAddSubPage("update")}>
                          Add Now
                        </button>
                      </>
                    )}
                  </VelocityTransitionGroup>
                </div>
                <div className="profileTab">
                  <div className="modalHeaderTabs">
                    {this.renderTabOptions()}
                  </div>
                </div>
              </>
            )}
            <div className="gap20" />
            {this.state.selectedTab === "details" && (
              <div
                className={
                  this.state.selectedTab === "details"
                    ? "active for-tab1"
                    : "for-tab1"
                }>
                {this.renderDetailsTab()}
              </div>
            )}

            {this.state.selectedTab === "content" && (
              <div
                className={
                  this.state.selectedTab === "content"
                    ? "active for-tab2"
                    : "for-tab2"
                }>
                {this.renderPageContentTab()}
              </div>
            )}

            {this.state.selectedTab === "rules" && (
              <div
                className={
                  this.state.selectedTab === "rules"
                    ? "active for-tab4"
                    : "for-tab4"
                }>
                {this.renderNotificationTab()}
              </div>
            )}

            {this.state.selectedTab === "settings" && (
              <div
                className={
                  this.state.selectedTab === "settings"
                    ? "active for-tab3"
                    : "for-tab3"
                }>
                {this.renderSettingsTab()}
              </div>
            )}
            {this.state.selectedTab === "funnel-settings" && (
              <div
                className={
                  this.state.selectedTab === "funnel-settings"
                    ? "active for-tab5"
                    : "for-tab5"
                }>
                {this.renderFunnelSettingsTab()}
              </div>
            )}
            {this.state.selectedTab === "registrations" && (
              <div
                className={
                  this.state.selectedTab === "registrations"
                    ? "active for-tab6"
                    : "for-tab6"
                }>
                <ViewSubmissions
                  key={this.state.pageId}
                  type="component"
                  landingPageId={this.state.pageId}
                />
              </div>
            )}
          </div>
        </div>
        <div className="text-right container-fluid">
          {this.state.pageId !== 0 && (
            <button
              className="btn btn-default margin-right-10"
              onClick={() => this.onPressPreview()}>
              Preview
            </button>
          )}
          {this.state.selectedTab === "details" && (
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSave()}>
              {this.state.pageId === 0
                ? "Create Landing Page"
                : "Update Landing Page"}
            </button>
          )}
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddLandingPage);
